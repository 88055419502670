@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table thead tr th,tr.summary-row td {
  color: black;
  background:#71d5f5 !important;
  /* text-decoration: */
}
.table tr{
  border:1px solid black !important
}
tr.not-editable {
  background: #eee;
}
.table td {
  border: 1px solid black;
}
.table tr td {
  font-size: 11px !important;
  padding: 0px 1px;
}

.coloumn-label{
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}

.contact-item-feature{
  white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.table-candidates .col1{
  width:100px;
}
.table-candidates .col2{
  width:50px;
}
.table-candidates .col5{
  width:150px;
  overflow: hidden;
}

.table-candidates td {
  overflow: hidden;
}

.candidate-action-buttons .btn-group i.bi {
  padding: 1px;
  font-size: 15px;
    border: 1px solid;
}
.contacts-container i {
  margin:0 5px;
}
.contact-item-feature {
  padding: 0;
}
.small-plus-button-icon{
  font-size: 15px;
    background: green;
    color: white;
    padding: 0 2px;
    border-radius: 3px;
}
.candidate-action-buttons .btn-group i.bi.download-button,.download-button {
  background-color: #90e3f7;
    color: blue !important;
}
.candidate-action-buttons .btn-group i.bi.format-button,.format-button {
  background-color: #198753;
  color: #cbefcb !important;
}
.candidate-action-buttons .btn-group i.bi.edit-button,.edit-button {
  background-color: rgb(255, 152, 0);
    color: #ffd89e;
}
.candidate-action-buttons .btn-group i.bi.delete-button,.delete-button {
  background-color: #de3848;
  color: #ffaeae !important;
}

.tdcell-div{
  width:250px;
    white-space: pre-wrap;
    word-break: break-word;
}

.companies-table td:nth-child(2) .tdcell-div {
  width: 90px;
}
.companies-table .table-sm>:not(caption)>*>* {
  padding: 0 10px;
}

.sticky-header th {
  /* position: sticky; */
  top: 50px;
  border-collapse: initial;
  z-index:1
}

.ats-dropdown {
  background-color: #50c2fe;
}


.logo {
  font-size: 24px;
  font-weight: 900;
}


/* Utilities */
.white-space-nowrap{
  white-space: nowrap;
}
.cursor-pointer{
  cursor:pointer;
}
.font-larger{
  font-size:larger;
}
.font-12{
  font-size:12px;
}
.font-20{
  font-size:20px;
}

.table-editable .editable td:empty, .table-editable .editable td.no-value {
  background-color: tomato;
}

.justify-content-space-between{
  justify-content: space-between;
}

.company-filter.list-group-item:hover {
  background: #0089ff36;
}
.table-fixed-layout{
  table-layout: fixed;
}
.width-50{
  width:50px;
}


.table-fixedheader thead,.table-fixedheader th{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.job_jobContainer__BRrtS {
    border: 1px solid #ccc;
    width: 500px;
    padding: 10px;
}

.job_logoContainer__2l9Ft {
    display: flex;
    justify-content: space-between;
    border-bottom:1px dotted #ccc;
    padding-bottom:10px;
}

.job_logoContainer__2l9Ft img {
    width: 200px;
}

.job_jobTitle__2Fuma {
    font-size: 40px;
    text-decoration: underline;
    text-align: center;
    width: 100%;
}

.job_labelRowLabel__oYXrA {
    font-size: 20px;
    color: #4cc2fe;
}

.job_labelRowValue__15YMp {
    font-size: 20px;
    color: #141414;
    margin-left: 10px;
}
.ATSNav_navWrapper__2yaOC {
  background-color: #4fc3fe;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  font-family: "Raleway", sans-serif;
}

.ATSNav_navWrapper__2yaOC .ATSNav_logo__3FAaN {
  /* background-color: #ffffff; */
  /* border-radius: 20px; */
}

.ATSNav_navWrapper__2yaOC .ATSNav_toggleIcon__1Tg8P {
  display: none;

}

.ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  width: 90%;
  margin: 0;
  text-align: center;

}

.ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V img {
  display: none;
}

.ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V li {
  list-style: none;
}

.ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V li a {
  text-decoration: none;
  color: #262626;
  font-weight:900;
}

@media (min-width: 320px) and (max-width: 920px) {
  .ATSNav_navWrapper__2yaOC {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .ATSNav_navWrapper__2yaOC .ATSNav_toggleIcon__1Tg8P {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    height:48px;
    width:48px;
  }
  .ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V {
    display: none;
  }
  .ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V.ATSNav_show__1dtyg {
    position: absolute;
    left: -500px;
    top: 0;
    bottom: 0;
    /* background-color: #160199; */
  background-color: #4fc3fe;

    width: 60%;
    height: 100vh;
    display: flex;
    justify-content: start;
    flex-direction: column !important;
    overflow-x: scroll;
    padding: 20px 0px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    animation: ATSNav_sidebarAnimationOn__28Wcb 1s forwards;
    z-index: 1
  }
  .ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V.ATSNav_show__1dtyg img {
    background-color: #ffffff;
    border-radius: 20px;
    display: inline-block;
  }
  .ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V.ATSNav_show__1dtyg li {
    margin-top: 10px;
    padding: 5px;
    cursor: pointer;
  }
  .ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V.ATSNav_hide__T0dBs {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  background-color: #4fc3fe;

    width: 60%;
    height: 100vh;
    display: flex;
    justify-content: start;
    flex-direction: column !important;
    overflow-x: scroll;
    padding: 20px 0px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    animation: ATSNav_sidebarAnimationOff__3KrC4 1s forwards;
    z-index:1;
  }
  .ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V.ATSNav_hide__T0dBs img {
    background-color: #ffffff;
    border-radius: 20px;
    display: inline-block;
  }
  .ATSNav_navWrapper__2yaOC .ATSNav_menuList__1q__V.ATSNav_hide__T0dBs li {
    margin-top: 20px;
    padding: 15px;
    cursor: pointer;
  }
  @keyframes ATSNav_sidebarAnimationOn__28Wcb {
    0% {
      left: -500px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes ATSNav_sidebarAnimationOff__3KrC4 {
    0% {
      left: 0;
    }
    100% {
      left: -550px;
    }
  }
}
/*# sourceMappingURL=EdufinNav.module.css.map */
.LoginPage_wrapper__1d7Wn {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG {
  width: 60%;
  background-color: #4fc3fe;
  display: flex;
  justify-content: center;
  padding: 30px;
  /* -webkit-box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.75); */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 0px;
  padding-top: 40px;
  position: relative;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG .LoginPage_bgImg__IFCsr {
  display: none;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG h1 {
  color: #000000;
  font-weight: 500;
  font-size: 22px;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG svg {
  width: 100% !important;
  position: absolute;
  bottom:0;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG img {
  width: 400px;
  height: 400px;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
  margin: auto 20px;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

          background: rgb(255 255 255 / 50%);
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg h1 {
  text-align: center;
  font-size: 38px;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg p {
  text-align: center;
  margin-bottom: 20px;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg p span {
  cursor: pointer;
  color: #4fc3fe;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg form input {
  /* margin-bottom: 20px; */
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  padding: 10px;
  /* width: 100% !important; */
  background-color: transparent;
}
.LoginPage_inputGroupText__1-LBH {
  background-color: transparent !important;
  border:none;
  border-bottom:1px solid;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg form button {
  width: 30%;
  margin: auto;
  /* background: #4fc3fe; */
  outline: none;
  border: none;
  padding: 10px 10px;
  color: #fff;
  border-radius: 20px;
}

.LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_footer__3rDKD {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4fc3fe;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG {
    width: 50%;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG h1 {
    font-size: 12px;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 {
    width: 50%;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_footer__3rDKD {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .LoginPage_wrapper__1d7Wn {
    position: relative;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG {
    width: 100%;
    padding-top: 0px;
    justify-content: flex-start;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG h1 {
    display: none;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG .LoginPage_vectorImg__XsSgQ {
    filter: blur(1px);
    margin-top: 40px;
    opacity: 0.2;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionOne__OZEzG svg {
    position: absolute;
    bottom: 0;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 {
    position: absolute;
    top: 20px;
    bottom: 10px;
    padding-top: 10px;
    width: 100%;
    margin: 0;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg {
    border-radius: 20px;
    padding: 10px;
      width: 99%;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg h1 {
    font-size: 12px;
    color: #ffffff;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg p {
    color: #ffffff;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg p span {
    color: #000000;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg form {
    color: #ffffff;
  }
  .LoginPage_wrapper__1d7Wn .LoginPage_sectionTwo__2ssy3 .LoginPage_login__ukfLg form input {
    border-radius: 10px;
    /* width: 100%; */
    /* border-bottom: 1px solid #ffffff; */
  }
}
/*# sourceMappingURL=LoginPage.module.css.map */
.FileViewer_highlight__2UzCA{
    background-color:rgb(255, 255, 0)
}
.GridReport_table__1TiYm,.GridReport_td__7RCRG{
    border:1px solid #ccc;
  }
  .GridReport_cell__q_roZ {
    border:1px solid #ccc;
    width: 25px;
    height: 25px;
  }
