@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
.navWrapper {
  background-color: #4fc3fe;
  padding: 5px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-family: "Raleway", sans-serif;
}

.navWrapper .logo {
  /* background-color: #ffffff; */
  /* border-radius: 20px; */
}

.navWrapper .toggleIcon {
  display: none;

}

.navWrapper .menuList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  width: 90%;
  margin: 0;
  text-align: center;

}

.navWrapper .menuList img {
  display: none;
}

.navWrapper .menuList li {
  list-style: none;
}

.navWrapper .menuList li a {
  text-decoration: none;
  color: #262626;
  font-weight:900;
}

@media (min-width: 320px) and (max-width: 920px) {
  .navWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .navWrapper .toggleIcon {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    height:48px;
    width:48px;
  }
  .navWrapper .menuList {
    display: none;
  }
  .navWrapper .menuList.show {
    position: absolute;
    left: -500px;
    top: 0;
    bottom: 0;
    /* background-color: #160199; */
  background-color: #4fc3fe;

    width: 60%;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
    overflow-x: scroll;
    padding: 20px 0px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -webkit-animation: sidebarAnimationOn 1s forwards;
            animation: sidebarAnimationOn 1s forwards;
    z-index: 1
  }
  .navWrapper .menuList.show img {
    background-color: #ffffff;
    border-radius: 20px;
    display: inline-block;
  }
  .navWrapper .menuList.show li {
    margin-top: 10px;
    padding: 5px;
    cursor: pointer;
  }
  .navWrapper .menuList.hide {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  background-color: #4fc3fe;

    width: 60%;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
    overflow-x: scroll;
    padding: 20px 0px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -webkit-animation: sidebarAnimationOff 1s forwards;
            animation: sidebarAnimationOff 1s forwards;
    z-index:1;
  }
  .navWrapper .menuList.hide img {
    background-color: #ffffff;
    border-radius: 20px;
    display: inline-block;
  }
  .navWrapper .menuList.hide li {
    margin-top: 20px;
    padding: 15px;
    cursor: pointer;
  }
  @-webkit-keyframes sidebarAnimationOn {
    0% {
      left: -500px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes sidebarAnimationOn {
    0% {
      left: -500px;
    }
    100% {
      left: 0;
    }
  }
  @-webkit-keyframes sidebarAnimationOff {
    0% {
      left: 0;
    }
    100% {
      left: -550px;
    }
  }
  @keyframes sidebarAnimationOff {
    0% {
      left: 0;
    }
    100% {
      left: -550px;
    }
  }
}
/*# sourceMappingURL=EdufinNav.module.css.map */