.jobContainer {
    border: 1px solid #ccc;
    width: 500px;
    padding: 10px;
}

.logoContainer {
    display: flex;
    justify-content: space-between;
    border-bottom:1px dotted #ccc;
    padding-bottom:10px;
}

.logoContainer img {
    width: 200px;
}

.jobTitle {
    font-size: 40px;
    text-decoration: underline;
    text-align: center;
    width: 100%;
}

.labelRowLabel {
    font-size: 20px;
    color: #4cc2fe;
}

.labelRowValue {
    font-size: 20px;
    color: #141414;
    margin-left: 10px;
}