.table thead tr th,tr.summary-row td {
  color: black;
  background:#71d5f5 !important;
  /* text-decoration: */
}
.table tr{
  border:1px solid black !important
}
tr.not-editable {
  background: #eee;
}
.table td {
  border: 1px solid black;
}
.table tr td {
  font-size: 11px !important;
  padding: 0px 1px;
}

.coloumn-label{
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}

.contact-item-feature{
  white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.table-candidates .col1{
  width:100px;
}
.table-candidates .col2{
  width:50px;
}
.table-candidates .col5{
  width:150px;
  overflow: hidden;
}

.table-candidates td {
  overflow: hidden;
}

.candidate-action-buttons .btn-group i.bi {
  padding: 1px;
  font-size: 15px;
    border: 1px solid;
}
.contacts-container i {
  margin:0 5px;
}
.contact-item-feature {
  padding: 0;
}
.small-plus-button-icon{
  font-size: 15px;
    background: green;
    color: white;
    padding: 0 2px;
    border-radius: 3px;
}
.candidate-action-buttons .btn-group i.bi.download-button,.download-button {
  background-color: #90e3f7;
    color: blue !important;
}
.candidate-action-buttons .btn-group i.bi.format-button,.format-button {
  background-color: #198753;
  color: #cbefcb !important;
}
.candidate-action-buttons .btn-group i.bi.edit-button,.edit-button {
  background-color: rgb(255, 152, 0);
    color: #ffd89e;
}
.candidate-action-buttons .btn-group i.bi.delete-button,.delete-button {
  background-color: #de3848;
  color: #ffaeae !important;
}

.tdcell-div{
  width:250px;
    white-space: pre-wrap;
    word-break: break-word;
}

.companies-table td:nth-child(2) .tdcell-div {
  width: 90px;
}
.companies-table .table-sm>:not(caption)>*>* {
  padding: 0 10px;
}

.sticky-header th {
  /* position: sticky; */
  top: 50px;
  border-collapse: initial;
  z-index:1
}

.ats-dropdown {
  background-color: #50c2fe;
}


.logo {
  font-size: 24px;
  font-weight: 900;
}


/* Utilities */
.white-space-nowrap{
  white-space: nowrap;
}
.cursor-pointer{
  cursor:pointer;
}
.font-larger{
  font-size:larger;
}
.font-12{
  font-size:12px;
}
.font-20{
  font-size:20px;
}

.table-editable .editable td:empty, .table-editable .editable td.no-value {
  background-color: tomato;
}

.justify-content-space-between{
  justify-content: space-between;
}

.company-filter.list-group-item:hover {
  background: #0089ff36;
}
.table-fixed-layout{
  table-layout: fixed;
}
.width-50{
  width:50px;
}


.table-fixedheader thead,.table-fixedheader th{
  position: sticky;
  top: 0;
}
